import { get, post } from "../config/api";
import { ServerData } from "../helpers/Service";
import { authorizationBetweenSystems } from "../helpers/Utils";

export interface Balcao {
  id: number;
  name: string;
  attendant: string;
  meet_link: string;
  status: string;
  date_initial: string;
  date_final: string;
  codigo_atendimento?: string;
  users_supported: Array<any>;
  date_start_mileseconds?: number;
  permit_other_users?: boolean
}

export interface BalcaoHistorico {
  id: number;
  name: string;
  attendant: string;
  duracao: string;
  users_supported: string;
  date_initial: string;
  date_final: string;
  status: string;
}

export interface BalcaoChangeStatus {
  attendant?: string,
  status: string
}

export interface Auth {
  username: string
  password: string
}

export interface Status {
  id: string,
  name: string
}
export default class BalcaoVirtualService {
  public static async balcoes() {
    return get<Balcao[]>("/balcoes/listar", {}, authorizationBetweenSystems())
      .then((response) => response);
  }

  public static async listMeetVersions(id:number) {
    return get<BalcaoHistorico[]>(`/balcoes/${id}/versions`, {}, authorizationBetweenSystems())
             .then(balcoes => balcoes);
  }

  public static async balcoesPorStatus(status: string) {
    return get<Balcao[]>("/balcoes/listar/", { status: status }, authorizationBetweenSystems())
      .then((response) => response);
  }

  public static async balcaoPorID(id: number) {
    return get<Balcao>(`/balcao/${id}`, {}, authorizationBetweenSystems())
      .then((response) => response);
  }

  public static async finishMeet(meet_link:string) {
    const auth = JSON.parse(sessionStorage.getItem('auth') || "")
    return post<Balcao>(`/finish_meet/${meet_link}`, {}, { auth} )
              .then(res => res);
  }

  public static async permitOtherUsers(id: number, params: { permitOtherUsers: boolean }) {
    const auth = JSON.parse(sessionStorage.getItem('auth') || "")
    return post<any>(`/permit_others_users/balcao/${id}`, { permit_other_users: params.permitOtherUsers }, { auth} )
              .then(res => res);
  }

  public static async changeStatus(params: BalcaoChangeStatus, id: string) {
    const auth = JSON.parse(sessionStorage.getItem('auth') || "")
    return post<Balcao>(`/balcoes/${id}/change_status`, params, { auth }
    ).then((response) => response);
  }

  public static async autenticar(auth: {}) {
    return post<ServerData<Boolean>>("/balcoes/autenticar", {}, { auth }
    ).then((response) => response.data);
  }

  public static async listStatuses() {
    return get<Array<Status>>("/balcoes/list_statuses", {}, authorizationBetweenSystems())
            .then((response) => response);
  }

  public static async listServices() {
    return get<Array<string>>("/balcoes/list_services", {}, authorizationBetweenSystems())
            .then((response) => response);
  }
}
