import { get, get_blob } from "../config/api";
import { authorization } from "../helpers/Utils";

export interface QueryReportParams {
    data_inicio?: any,
    data_termino?: any,
    atendente?: string,
    status_eq?: string,
    servico_eq?: string,
}

export default class RelatoriosService {
  public static async generate({data_inicio, data_termino, atendente, status_eq, servico_eq}: QueryReportParams) {
    let query_string = `q[date_initial_gteq]=${data_inicio}&q[date_final_lteq]=${data_termino}&q[attendant_eq]=${atendente}&q[status_eq]=${status_eq}&q[user_supporteds_service_eq]=${servico_eq}`
    return get_blob<any>(`/reports/history_attendences?${query_string}`, {}, authorization() )
            .then(res => res);
  }

  public static async updateGraphics({data_inicio, data_termino, atendente, status_eq, servico_eq}: QueryReportParams) {
    let query_string = `q[date_initial_gteq]=${data_inicio}&q[date_final_lteq]=${data_termino}&q[attendant_eq]=${atendente}&q[status_eq]=${status_eq}&q[user_supporteds_service_eq]=${servico_eq}`
    return get<any>(`/reports/history_attendences_graphics?${query_string}`, {}, authorization() )
            .then(res => res);
  }
}
