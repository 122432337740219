import React from "react";
import Home from "../../components/LandingPage/Home"
import "./style.css";

import Section8 from "../../components/LandingPage/Section8"

export default function LandingPage() {
  return (
    <>
      <Home />
      <div className="section8">
        <div className="container-fluid">
          <Section8 />
        </div>
      </div>

    </>
  )
}
