import axios, { AxiosError, AxiosResponse } from "axios";
export const URLS = {
  development: process.env.REACT_APP_ENDPOINT_ATENDIMENTO_BACK?.replace("3001", "5001"),
  staging: process.env.REACT_APP_ENDPOINT_ATENDIMENTO_BACK,
  production: process.env.REACT_APP_ENDPOINT_ATENDIMENTO_BACK,
};
type Environments = "production" | "staging" | "development";
// console.log(process.env)
export function env(): Environments {
  const host = window.location.host;
  if (process.env.REACT_APP_ENV == 'staging') {
    return "staging";
  }

  if (process.env.REACT_APP_ENV == 'production') {
    return "production";
  }

  return "development";
}

const api = axios.create({
  baseURL: URLS[env()],
  responseType: "json",
  timeout: 3600000,
  timeoutErrorMessage: "A solicitação que fez demorou muito. Por favor tente novamente.",
  headers: {
    "Content-Type": "application/json",
    "X-Request-Client": "Portal",
  },
});

export const api_blob = axios.create({
  baseURL: URLS[env()],
  responseType: "blob",
  timeout: 3600000,
  timeoutErrorMessage: "A solicitação que fez demorou muito. Por favor tente novamente.",
  headers: {
    "Content-Type": "application/json",
    "X-Request-Client": "Portal",
  },
});

export default api;

interface ServerError {
  error: string;
}

export function isServerError(error: ServerError | unknown): error is ServerError {
  if (typeof error === "object" && error !== null && error.hasOwnProperty("error")) {
    // tslint:disable-next-line: strict-type-predicates
    return (error as ServerError).error !== undefined;
  }

  return false;
}

export async function get<I>(url: string, params = {}, headers = {}) {
  return api
    .get<I>(url, {params, headers} )
    .then((response: AxiosResponse<I>) => response.data)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function post<I>(url: string, params = {}, config = {}) {
  return api
    .post<I>(url, params, config)
    .then((response: AxiosResponse<I>) => response.data)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}

export async function get_blob<I>(url: string, params = {}, headers = {}) {
  return api_blob
    .get<I>(url, {params, headers} )
    .then((response: AxiosResponse<I>) => response.data)
    .catch((error: AxiosError<ServerError>) => {
      if (error.response) { throw error.response.data; }
      throw error;
    });
}
