import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { withTracker } from "./google-analytics";
import Home from "../views/Home";
import ExecucaoFiscal from "../views/ExecucaoFiscal";
import ConsultarDebitos from "../views/ConsultarDebitos";
import DuvidasFrequentes from "../views/DuvidasFrequentes";
import AgendarAtendimento from "../views/AgendarAtendimento";
import PaginaNaoEncontrada from "../views/PaginaNaoEncontrada";
import ConsultarParcelamentos from "../views/ConsultarParcelamentos";
import AtendimentosEspecializados from "../views/AtendimentosEspecializados";
import ValidarDocumentoAssinado from "../views/ValidarDocumentoAssinado";
import AreaAtendente from "../views/AreaAtendente";
import RelatoriosHistorico from "../views/RelatoriosHistorico";
import Login from "../views/Login"
import LandingPage from '../views/LandingPage';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/" exact component={withTracker(Home)} /> */}
        <Route path="/" exact component={withTracker(LandingPage)} />
        {/* <Route path="/paineis/duvidas_frequentes" component={withTracker(DuvidasFrequentes)} /> */}
        {/* <Route path="/paineis/orientacoes_sobre_execucao_fiscal" component={withTracker(ExecucaoFiscal)} /> */}
        {/* <Route path="/atendimentos_especializados" component={withTracker(AtendimentosEspecializados)} /> */}
        {/* <Route path="/consultar/debitos/em_aberto" component={withTracker(ConsultarDebitos)} /> */}
        {/* <Route path="/consultar/parcelamentos" component={withTracker(ConsultarParcelamentos)} /> */}
        {/* <Route path="/validar-documento" component={withTracker(ValidarDocumentoAssinado)} /> */}
        <Route path="/area_atendente" component={withTracker(AreaAtendente)} />
        <Route path="/relatorio_de_atendimento" component={withTracker(RelatoriosHistorico)} />
        <Route path="/login" component={withTracker(Login)} />
        {/* <Route path="/agendar/atendimento" component={withTracker(AgendarAtendimento)} /> */}
        <Route path="*" component={withTracker(PaginaNaoEncontrada)} />
      </Switch>
    </BrowserRouter>
  );
}
