import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock} from "@fortawesome/free-solid-svg-icons"


export default function Cronometro(props:any) {
  const [seg, setSeg] = useState(0);
  const [min, setMin] = useState(0);
  const [hour, setHour] = useState(0);
  const now = new Date();

  useEffect(() => {
    setTimeout(() => {
      const initial_date = props.date_initial*1000
      const time_segs = Math.round((now.getTime() - initial_date)/1000)

      setHour( Math.floor(time_segs/3600))
      setMin( Math.floor((time_segs % 3600) / 60))
      setSeg( Math.floor(time_segs % 3600) % 60)
    }, 1000);
  });

  return (
    <>
     <div className="tempo-atendimento">
       <FontAwesomeIcon  className="" icon={faClock} />
      { String(hour).padStart(2, '0') + ':' + String(min).padStart(2, '0') + ':' + String(seg).padStart(2, '0') }
    </div>
    </>


  )

}
