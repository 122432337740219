import { useLocation } from "react-router-dom";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function authorization() {
  let auth:any = sessionStorage.getItem('auth')
  auth = JSON.parse(auth || '');

  const token = Buffer.from(`${auth?.username}:${auth?.password}`, 'utf8').toString('base64');

  return { Authorization: `Basic ${token}`};
}

export function authorizationBetweenSystems() {
  const token = Buffer.from(`${process.env.REACT_APP_ATENDIMENTO_BACK_USERNAME}:${process.env.REACT_APP_ATENDIMENTO_BACK_PASSWORD}`, 'utf8').toString('base64');
  const header = { Authorization: `Basic ${token}`};

  return header;
}
