import React, { useState } from 'react';
// @ts-ignore
import * as Highcharts from 'highcharts';
// @ts-ignore
import HighchartsReact from 'highcharts-react-official';

interface Props {
  data: Array<Array<any>>,
  title: string,
  x_title: string,
  colorByPoint: boolean
}

const HorizontalBarChart = ({data, title, x_title, colorByPoint}: Props) => {
  let options: Highcharts.Options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: title,
      style: {
        fontSize: '15px'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: x_title,
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      }
    },
    xAxis: {
      categories: data.map(function (d) { return d[0] }),
      title: {
        text: null
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: 'Total: {point.y}'
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        },
        colorByPoint: colorByPoint
      },
      // @ts-ignore
    },
    credits: {
      enabled: false
    },
    // @ts-ignore
    series: [{
      // @ts-ignore
      data: data.map(function (d) { return d[1] })
    }]
  };

  return <>
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  </>
};

export default HorizontalBarChart;
