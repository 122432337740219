import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import imageContribuinte from "../../../assets/images/landingPage/image-contribuinte.svg";
import Navbar from '../NavBar'
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
import "./style.css";

export default function Home() {

  return (
    <>
      <div id="home" className="home-container">
        <div className="container">
          {/* <Navbar /> */}
        </div>
      </div>
    </>
  );
}
