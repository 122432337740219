import { post } from "../config/api";

interface Auth {
  username: string
  password: string
}

export default class AuthService {
  public static async whoiam() {
    const auth:Auth = JSON.parse(sessionStorage.getItem('auth') || '');
    return post<any>(`/whoiam`, {}, { auth })
            .then((response) => response);
  }
}
