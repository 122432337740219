import { get } from "../config/api";
import { authorization } from "../helpers/Utils";

type ReponseList = {
  ok: boolean,
  attendantes: Array<string>
}

export default class AtendentesService {
  public static async list() {
    return get<ReponseList>("/atendentes", {}, authorization()).then((response) => response);
  }
}
