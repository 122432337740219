import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "../../helpers/Utils";
import Breadcrumb from "../Breadcrumb";
import "./style.css";

import logo from "../../assets/images/badge-ceara1.png";
// import EnviromentMessage from "../EnviromentMessage";

interface Props {
  items: { label: string; to?: string; active?: boolean }[];
}

export default function Container({ children, items }: React.PropsWithChildren<Props>) {
  const query = useQuery();
  const digital = Boolean(query.get("digital"));
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    let auth = sessionStorage.getItem('auth')

    setIsLogged(Boolean(auth));
  }, [])

  return (
    <>
      {/* <EnviromentMessage /> */}
      <nav className="navbar navbar-light navbar-pmf">
        {
          !isLogged && <Link to="/login" className="navbar-brand" >
          <img src={logo} height='50' />
        </Link>
        }

        {
          isLogged &&
          <>
            <Link to="/area_atendente" className="navbar-brand" >
              <img src={logo} height='50' />
            </Link>
            <Link to="/relatorio_de_atendimento" className="btn btn-primary" >
              Relatórios de Atendimentos
            </Link>
          </>
        }

      </nav>
      {!digital && (
        <nav className="navbar navbar-light navbar-main">
          <div className="container">
            <Link to="/" className="navbar-brand pl-2" title="Voltar para o início">
            </Link>
          </div>
        </nav>
      )}
      <div className={`container ${digital && "container-digital"} container-pmf`}>
        {!digital && <Breadcrumb items={items} />}
        {children}
      </div>
    </>
  );
}
