import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import fileDownload from 'js-file-download';

import Button from '../../components/Form/Button';
import RelatoriosService, { QueryReportParams } from '../../services/RelatoriosService';
import AtendentesService from '../../services/AtendentesService';
import BalcaoVirtualService, { Status } from '../../services/BalcaoVirtualService';
import Graphics from './graphics';
import './search.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSync, faDownload } from '@fortawesome/free-solid-svg-icons';

// @ts-ignore
import pt from 'date-fns/locale/pt';
// @ts-ignore
import { registerLocale } from  "react-datepicker";
// @ts-ignore
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import GraphicData from './interfaces/graphic';
import * as Yup from "yup";
import MaskedInput from 'react-maskedinput'
import moment from 'moment';

registerLocale('pt', pt)

const current_date = moment().startOf('day');
const max_date = moment().endOf('day');

const validationSchema = Yup.object({
  data_inicio: Yup.date()
                  .default(() => moment().startOf('month').toDate())
                  .max(current_date.toDate())
                  .required(),
  data_termino: Yup.date()
                   .default(max_date.toDate())
                   .max(max_date.toDate())
                   .required(),
  atendente: Yup.string(),
  servico_eq: Yup.string(),
  status_eq: Yup.string()
});

const initialQuerySearchParams: QueryReportParams = {
  data_inicio:  moment().startOf('month').toDate(),
  data_termino: max_date.toDate(),
  atendente: '',
  servico_eq: '',
  status_eq: ''
}

export default function Search() {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [atendentes, setAtendentes] = useState<Array<string>>([]);
  const [statuses, setStatuses] = useState<Array<Status>>([]);
  const [services, setServices] = useState<Array<string>>([]);
  const [searchForm, setSearchForm] = useState<QueryReportParams>(initialQuerySearchParams);
  const [graphicData, setGraphicData] = useState<GraphicData>({
    reports_per_attendants: [],
    reports_per_services: [],
    count_attendants: 0
  });
  const [preparingDownload, setPreparingDownload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [period, setPeriod] = useState('')

  useEffect(() => {
    Promise.all([loadAtendentes(), loadStatuses(), loadServices(), updateGraphics()])
      .then(function (results) {
        setAtendentes(results[0].attendantes);
        // @ts-ignore
        setStatuses(results[1].statuses);
        // @ts-ignore
        setServices(results[2].services);
      }).catch((err) => {
        history.push("/login")
      });
  }, []);

  const loadServices = async () => await BalcaoVirtualService.listServices();
  const loadAtendentes = async () => await AtendentesService.list();
  const loadStatuses = async () => await BalcaoVirtualService.listStatuses();
  const updateGraphics = async () => {
    try {
      await validationSchema.validate(searchForm);
      setLoading(true)
      const response = await RelatoriosService.updateGraphics(searchForm);
      setPeriod(`<br/><b>${moment(searchForm.data_inicio).format("DD/MM/YYYY")} a ${moment(searchForm.data_termino).format("DD/MM/YYYY")}</b>`)

      setGraphicData(response);
      setLoading(false)
    } catch (err) {
      console.warn(err.errors)
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(searchForm);
      setPreparingDownload(true)
      updateGraphics()
      RelatoriosService.generate(searchForm)
        .then(response => {
          fileDownload(response, `${Date.now()}-relatorio-de-atendimentos.xlsx`)
        }).then(() => setPreparingDownload(false))
    } catch (err) {
      setPreparingDownload(false)
      console.warn(err.errors)
    }
  };

  return <div>
    <Form ref={formRef} onSubmit={handleSubmit} className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label className="form-label">Data Início:</label>
          <DatePicker
            selected={searchForm.data_inicio}
            locale="pt"
            className="form-control "
            dateFormat="dd/MM/yyyy"
            onChange={(value:Date) => setSearchForm({ ...searchForm, data_inicio: value })}
            customInput={<MaskedInput mask="11/11/1111" placeholder="DD/MM/YYYY"  />}
          />
          {!!!searchForm.data_inicio && <span className={"text-danger"}>Informe uma data</span>}
          {searchForm.data_inicio > max_date.toDate() && <span className={"text-danger"}>Data Início não pode ser uma data futura.</span>}
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label className="form-label">Data Términio:</label>
          <DatePicker
            selected={searchForm.data_termino}
            locale="pt"
            onChange={(value:Date) => setSearchForm({ ...searchForm, data_termino: value })}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            customInput={<MaskedInput mask="11/11/1111" placeholder="DD/MM/YYYY"  />}
          />
          {!!!searchForm.data_termino && <span className={"text-danger"}>Informe uma data</span>}
          {searchForm.data_termino > max_date.toDate() && <span className={"text-danger"}>Data Término não pode ser uma data futura.</span>}
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>Atendente:</label>
          <select className="form-control" onChange={e => setSearchForm({ ...searchForm, atendente: e.target.value })} name="atendente_eq">
            <option value={""}>
              Todos
            </option>
            {atendentes?.map((atendente) =>
              <option
                key={atendente}
                value={atendente}
              >
                {atendente}
              </option>)}
          </select>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>Status:</label>
          <select className="form-control" onChange={e => setSearchForm({ ...searchForm, status_eq: e.target.value })} name="status_eq">
            <option value={""}>
              Todos
            </option>
            {statuses?.map((status) =>
              <option
                key={status.id}
                value={status.id}
              >
                {status.name}
              </option>)}
          </select>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>Serviço:</label>
          <select className="form-control" onChange={e => setSearchForm({ ...searchForm, servico_eq: e.target.value })} name="status_eq">
            <option value={""}>
              Todos
            </option>
            {services?.map((service) =>
              <option
                key={service}
                value={service}
              >
                {service}
              </option>)}
          </select>
        </div>
      </div>

      <div className="col-md-6 align-end-justify-start" style={{ justifyContent: 'space-around' }}>
        <Button type="submit" label="" name="submit" disabled={preparingDownload}>
          { preparingDownload &&  <><span style={{ marginRight: '0.5em' }}>Exportando... </span><FontAwesomeIcon icon={faSpinner} spin /></> }
          {
            !preparingDownload && <><span style={{ marginRight: '0.5em' }}>Exportar XLSX </span><FontAwesomeIcon icon={faDownload} /></>
          }
        </Button>
        <Button
          type="button"
          className="btn btn-secondary"
          label=""
          onClick={updateGraphics}
        >
          { loading &&  <><span style={{ marginRight: '0.5em' }}>Atualizando... </span><FontAwesomeIcon icon={faSpinner} spin /></> }
          {
            !loading && <><span style={{ marginRight: '0.5em' }}>Atualizar Gráficos </span><FontAwesomeIcon icon={faSync} /></>
          }
        </Button>
      </div>
    </Form>

    <div className="row mt-2">
      <div className="col-md-12">
        <Graphics
          reports_per_attendants={graphicData.reports_per_attendants}
          reports_per_services={graphicData.reports_per_services}
          count_attendants={graphicData.count_attendants}
          period={period}
        />
      </div>
    </div>
  </div>;
};
