import React from "react";
import Container from "../../components/Container";
import Search from "./search";

export default function AreaAtendente() {
  const breadcrumbs:Array<{ label: string, to?: string, active?: boolean }> = [
    { label: "Página Inicial", to: "/" },
    {label: "Área do Atendente", to: "/area_atendente"},
    { label: "Relatório de Atendimentos", active: true }
  ];

  return (
    <Container items={breadcrumbs}>
      <Search />
    </Container>
  );
}
