import React, { memo } from 'react';
import HorizontalBarChart from '../../components/HorizontalBarChart';

import GraphicData from './interfaces/graphic';

function Graphics(props: GraphicData) {
  return <div>
    <div className="row">
      <div className="col-md-4">
        <HorizontalBarChart
          data={props.reports_per_attendants}
          title={`Quantidade de atendimentos por Atendente no período ${props.period}`}
          // x_title={`${props.count_attendants} atendimentos.`}
          x_title={`${props.reports_per_attendants.map((item) => item[1]).reduce((total, val) => total + val, 0)} atendimentos.`}
          colorByPoint={false}
        />
      </div>

      <div className="col-md-8">
        <HorizontalBarChart
          data={props.reports_per_services}
          title={`Quantidade de atendimentos por Serviço no período ${props.period}`}
          // x_title={`${props.count_attendants} atendimentos.`}
          x_title={`${props.reports_per_services.map((item) => item[1]).reduce((total, val) => total + val, 0)} atendimentos.`}
          colorByPoint
        />
      </div>
    </div>
  </div>;
};

export default memo(Graphics);
