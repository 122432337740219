import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BalcaoVirtualService, { Balcao, BalcaoHistorico } from "../../services/BalcaoVirtualService";
interface HistoricoBalcaoProps {
  guicheSelecionado: Balcao,
  statusSelecionado: string
}

type statusesOptions = {
  [key: string]: Function
}

export default function HistoricoBalcao(props: HistoricoBalcaoProps) {

  const [guicheHistorico, setGuicheHistorico] = useState<BalcaoHistorico[]>([]);
  // const [statusSelecionado, setStatusSelecionado] =  useState(' ');

  const loadBalcoesVersions = (id: number) => {
    BalcaoVirtualService.listMeetVersions(id)
      .then((response) => {
        // @ts-ignore
        setGuicheHistorico(response);
      });
  }

  useEffect(() => {
    // setStatusSelecionado(props.statusSelecionado);
    loadBalcoesVersions(props.guicheSelecionado?.id)
  }, [props.statusSelecionado])

  const statuses: statusesOptions = {
    unavailable: () => 'Indisponível',
    available: () => 'Disponível',
    busy: () => 'Ocupado',
    pause: () => 'Disponível',
    finish: () => 'Finalizado'
  }

  const statusesBadge: statusesOptions = {
    unavailable: () => 'secondary',
    available: () => 'success',
    busy: () => 'danger',
    pause: () => 'secondary',
    finish: () => 'secondary'
  }

  return (
    <>
      <b>Últimos Atendimentos</b>

      <div>
        <span>{props.guicheSelecionado?.name}</span>
      </div>

      <div className="table-responsive">
        <table className="table table-sm table-bordered table-striped">
          <thead>
            <tr>
              <th>Atendente</th>
              <th>Contribuintes</th>
              <th>Min</th>
              <th>Inicio</th>
              <th>Fim</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '13px', overflowY: 'auto' }}>
            {guicheHistorico && guicheHistorico.map((guiche, index) => (
              <tr key={index} >
                <td >
                  {guiche?.attendant}
                  <br />
                  <span className={`badge rounded-pill bg-${statusesBadge[guiche.status]()} text-white`} >{statuses[guiche.status]()}</span>
                </td>
                <td >
                  {guiche?.users_supported.split(',').map((item, index) => {
                    return (
                      <div key={`item-${index}`}>
                        {item}
                        <br />
                      </div>
                    )
                  })}
                </td>
                <td >{guiche?.duracao}</td>
                <td >{guiche?.date_initial}</td>
                <td >{guiche?.date_final}</td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </>
  )
}
