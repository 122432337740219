import React, { useState, useRef, useEffect } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";

import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import Container from "../../components/Container";
import BalcaoVirtualService, { Auth } from "../../services/BalcaoVirtualService"

import "moment/locale/pt-br";
import "react-day-picker/lib/style.css";
import "./style.css";
import Alert from "../../components/Alert";
import api from "../../config/api";
import AuthService from "../../services/AuthService";

interface FormData {
  username: string;
  password: string
}

export default function Login() {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function load() {
      setLoading(true);
      await AuthService.whoiam().then((_response) => {
          history.push('/area_atendente');
        }).catch((err) => {
          setLoading(false);
          setError("Houve um problema com o login, verifique suas credenciais.");
         }).finally(() => {
          setLoading(false);
        });
    }
    load();
  }, []);

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      formRef.current?.setErrors({});
      setLoading(true);
      const auth: Auth = {
        username: formData.username,
        password: formData.password
      }

      if (auth.username == '' || auth.password == '') {
        setError("Preencha usuário e senha para continuar!");
        setLoading(false);
      }
      else {
        await BalcaoVirtualService.autenticar(auth)
          .then((response) => {
            sessionStorage.setItem('auth', JSON.stringify(auth));
            history.push('/area_atendente');
          }).catch((err) => {
            setLoading(false);
            setError("Houve um problema com o login, verifique suas credenciais.");
           }).finally(() => {
            setLoading(false);
          });
      }
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Container items={[{ label: "Página Inicial", to: "/" }, { label: "Área do atendente", active: true }]}>
      <p className="text-info-atendente">Acesse sua área com usuário e senha.</p>
      <Form className="form-login" ref={formRef} onSubmit={handleSubmit}>
        {error && (<Alert>{error}</Alert>)}
        <div className="row">
          <div className="col-4">
            <Input label="Usuário" name="username" maxLength={50} autoFocus />
          </div>
          <div className="col-4">
            <Input type="password" label="Senha" name="password" maxLength={50} />
          </div>
          <div className="col-4 mt-auto">
            <Button className="btn btn-primary btn-login" label="Acessar" loading={loading} />
          </div>
        </div>
      </Form>
    </Container >
  );
}
