import React, { useState, useRef, useEffect } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCoffee, faUserAlt, faStop, faPlay, faDoorOpen, faVideo } from "@fortawesome/free-solid-svg-icons"
import BalcaoVirtualService, { Balcao, BalcaoChangeStatus } from "../../services/BalcaoVirtualService";
import Button from "../../components/Form/Button";
import Container from "../../components/Container";
import { useHistory } from "react-router-dom";
import Cronometro from "./Cronometro";

import "moment/locale/pt-br";
import "react-day-picker/lib/style.css";
import "./style.css";
import Alert from "../../components/Alert";
import ModalConfirm from "../../components/ModalConfirm";
import AuthService from "../../services/AuthService";
import Input from "../../components/Form/Input";
import HistoricoBalcao from "./HistoricoBalcao";
import Checkbox from "../../components/Form/Checkbox";

interface OptionStatus {
  value: string,
  label: string,
  description: string
}

export default function AreaAtendente() {
  const UNAVAILABLE: string = 'unavailable'
  const AVAILABLE: string = 'available'
  const BUSY: string = 'busy'
  const PAUSE: string = 'pause'
  const FINISH: string = 'finish'

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [statusSelecionado, setStatusSelecionado] = useState(UNAVAILABLE);
  const [balcoes, setBalcoes] = useState<Balcao[]>([]);
  const [guicheSelecionado, setGuicheSelecionado] = useState<Balcao>();
  const [idBalcaoSelecionado, setIdBalcaoSelecionado] = useState("");
  const [renderIcon, setRenderIcon] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [attendant, setAttendant] = useState("");
  const [permitOthersUsers, setPermitOthersUsers] = useState(true);

  const optionsStatus: OptionStatus[] = [
    { value: UNAVAILABLE, label: 'Indisponível', description: 'selecione esse status quando não puder atender chamadas.' },
    { value: AVAILABLE, label: 'Disponível', description: 'enquanto aguarda contribuinte entrar na chamada.' },
    { value: BUSY, label: 'Ocupado', description: 'selecione esse status quando algum contribuinte entrar na chamada.' },
    { value: PAUSE, label: 'Pausa', description: 'selecione esse status quando precisar fazer uma pausa entre um atendimento e outro.' },
    { value: FINISH, label: 'Finalizado', description: 'esse status é selecionado quando algum atendimento é finalizado.' },
  ]

  useEffect(() => {
    disabledF5AndSureClose();
    if (!!sessionStorage.getItem('attendant')) {
      setAttendant(sessionStorage.getItem('attendant') || '')
    }

    Promise.all([loadBalcoes(), load()])
      .then(function (results) {
        const balcoesLista: Array<Balcao> = results[0];
        setBalcoes(balcoesLista);
        loadStates(balcoesLista);
      }).catch((err) => {
        setLoading(false);
        history.push("/login")
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  function loadStates(balcoes: Array<Balcao>) {
    const balcaoSelecionado = sessionStorage.getItem("balcao")
    const statusSelecionado = sessionStorage.getItem("status")

    if (balcaoSelecionado && statusSelecionado) {
      let balcaoSelecionadoAnteriormente: Balcao = balcoes.filter((balcao) => balcao.id == parseInt(balcaoSelecionado))[0];
      setGuicheSelecionado(balcaoSelecionadoAnteriormente || undefined);

      setPermitOthersUsers(Boolean(balcaoSelecionadoAnteriormente.permit_other_users))
      setRenderIcon(true);
      setIdBalcaoSelecionado(balcaoSelecionado);
      updateStatusSelecionado(statusSelecionado);
    } else {
      updateStatusSelecionado(UNAVAILABLE)
    }
  }

  async function loadBalcoes() {
    const response = await BalcaoVirtualService.balcoes()

    return response;
  };

  async function load() {
    const response = await AuthService.whoiam();

    return response;
  };

  const handleSubmit = async () => {
    try {
      if (!Boolean(attendant)) {
        setError("Por favor, informe seu nome.")
        return;
      }

      if (idBalcaoSelecionado !== "") {
        setLoading(true);
        formRef.current?.setErrors({});
        setDisabled(true);
        setRenderIcon(true);
        sessionStorage.setItem("balcao", idBalcaoSelecionado);
        sessionStorage.setItem("status", statusSelecionado);
        let paramsBalcao: BalcaoChangeStatus = { status: statusSelecionado, attendant: attendant }
        await BalcaoVirtualService.changeStatus(paramsBalcao, idBalcaoSelecionado)
          .then((response) => {
            setPermitOthersUsers(Boolean(response.permit_other_users))
            loadBalcoes();
            setGuicheSelecionado(response);
            setLoading(false);
            (response?.status == AVAILABLE) && pushLinkMeet(response);

          });
      } else {
        setError("Selecione uma sala.")
      }
    } catch (err) {
      setError(err)
      setLoading(false);
    }
  };

  // @ts-ignore
  const pushLinkMeet = (guiche?: any) => {
    let guicheData = guiche.type == 'click' ? guicheSelecionado : guiche
    let system_atendimento_param: string = "system_atendimento=sistema_atendimento_sefin"
    let codigo_atendimento_param: string = `codigo_atendimento=${guicheData?.codigo_atendimento}`
    let nome_atendente: string = `name=${attendant}`
    let query_params: string = `${nome_atendente}&${codigo_atendimento_param}&${system_atendimento_param}`;

    window.open(`${guicheData?.meet_link}?${query_params}`, "_blank");
  }

  const logout = async () => {
    const balcaoSelecionado = (sessionStorage.getItem("balcao") || "")
    if (balcaoSelecionado) {
      let paramsBalcao: BalcaoChangeStatus = { status: UNAVAILABLE, attendant: "" }
      await BalcaoVirtualService.changeStatus(paramsBalcao, balcaoSelecionado)
        .then(() => { })
    }
    sessionStorage.clear();
    window.location.reload();
  };

  const changeStatus = async (statusSelecionado: string) => {
    setLoading(true);
    if (idBalcaoSelecionado !== "") {
      updateStatusSelecionado(statusSelecionado);

      let paramsBalcao: BalcaoChangeStatus = { status: statusSelecionado, attendant: attendant }
      await BalcaoVirtualService.changeStatus(paramsBalcao, idBalcaoSelecionado)
        .then((response) => {
          setGuicheSelecionado(response)
          setLoading(false);
          (response?.status == AVAILABLE) && pushLinkMeet(response);
        });
    }
  }

  const handleShowModalConfirm = (event: React.MouseEvent<HTMLElement>) => {
    window.onbeforeunload = null;
    event.preventDefault();
    setShowModalConfirm(true);
  };

  const handleHideModalConfirm = () => {
    setShowModalConfirm(false);
  };

  const handleChangeAttendant = (value: string) => {
    setAttendant(value)
    sessionStorage.setItem("attendant", value);
  }

  const handleShowMeetLink = () => {
    let status: string = guicheSelecionado?.status || ''
    return [AVAILABLE, BUSY].includes(status) && Boolean(guicheSelecionado?.meet_link)
  }

  const updateStatusGuiche = () => {
    if (Boolean(guicheSelecionado)) {
      BalcaoVirtualService.balcaoPorID(guicheSelecionado?.id || 0).then(res => {
        if (res?.status !== statusSelecionado) {
          updateStatusSelecionado(res?.status);
          // Adicionado também atualização do state guicheSelecionado
          // Devido a atualizações que são feitas diretamente na base de dados
          // e os componentes ficam com os stados desatualizados
          // NOTE: Verificar no futuro a utilização de websocket no backend rails
          setGuicheSelecionado(res);
        }
      })
    }
  }

  const updateStatusSelecionado = (status: string) => {
    setStatusSelecionado(status)
    sessionStorage.setItem("status", status)
  }

  const handlePause = () => {
    changeStatus(PAUSE);

  }

  const handleUnvailable = () => {
    changeStatus(UNAVAILABLE);

  }

  const handleFinish = () => {
    let meetLink = guicheSelecionado?.meet_link;
    meetLink = meetLink?.split('/')[meetLink?.split('/').length - 1]
    changeStatus(FINISH);
    BalcaoVirtualService.finishMeet(meetLink || '').then(() => { });

  }

  const handleAvailable = () => {
    // updateStatusSelecionado(AVAILABLE)
    changeStatus(AVAILABLE);
  }

  useEffect(() => {
    let intervalo = setInterval(() => { updateStatusGuiche() }, 5000)

    return () => { clearInterval(intervalo) };
  }, [guicheSelecionado])

  const disabledF5AndSureClose = () => {
    $(function () {
      var areYouReallySure = false;
      function areYouSure() {
        if (allowPrompt) {
          if (!areYouReallySure && true) {
            areYouReallySure = true;
            var confMessage = "***************************************\n\n W A I T !!! \n\nBefore leaving our site, follow CodexWorld for getting regular updates on Programming and Web Development.\n\n\nCLICK THE *CANCEL* BUTTON RIGHT NOW\n\n***************************************";
            return confMessage;
          }
        } else {
          allowPrompt = true;
        }
      }
      var allowPrompt = true;
      window.onbeforeunload = areYouSure;


      $(document).keydown(function (e) {
        return (e.which || e.keyCode) != 116;
      });
    });
  }

  const handleChangePermitOtherUsers = async (e: any) => {
    await BalcaoVirtualService.permitOtherUsers(parseInt(idBalcaoSelecionado), { permitOtherUsers: !permitOthersUsers })
      .then((response: { ok: boolean, balcao: Balcao }) => {
        setPermitOthersUsers(Boolean(response?.balcao?.permit_other_users))
      });
  }

  const renderSelectGuiche = () => {
    return <>
      <div className="row">
        <div className="col-12 col-sm-6 col-lg-12">
          <p className="h6">Escolha uma sala de atendimento e depois clique entrar na sala para iniciar atendimento</p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="form-group">
            <Input label="Nome" name="documento" value={attendant} autoFocus onChange={e => handleChangeAttendant(e.target.value)} />
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>Sala:</label>
            <select value={idBalcaoSelecionado} className="form-control" disabled={disabled} onChange={e => setIdBalcaoSelecionado(e.target.value)}>
              <option value={""}>
                Selecione uma sala
              </option>
              {balcoes?.map((option) =>
                <option
                  // disabled={option.status !== UNAVAILABLE}
                  key={option.id}
                  value={option.id}>
                  {`${option.name} ${Boolean(option.attendant) ? `(Em uso por ${option.attendant})` : ''}`}
                </option>)}
            </select>
            {error && <div className="invalid-feedback">{error}</div>}
          </div>
        </div>
        <div className="col-12">
          <div className="flex">
            <Button className="btn btn-primary btn-select-balcao mr-2" label="Utilizar Sala" disabled={disabled} loading={loading} />
            {renderIcon && <FontAwesomeIcon className="mt-2 balcao-check" icon={faCheckCircle} />}
          </div>
        </div>
      </div>
    </>
  }

  return (
    <Container items={[{ label: "Página Inicial", to: "/" }, { label: "Área do atendente", active: true }]}>
      <p className="text-info-atendente">Bem-vindo(a) <b>{attendant && attendant}</b>!</p>
      <div className="row">
        <a className="ml-auto mr-2 btn-logout" onClick={handleShowModalConfirm} href="">Sair</a>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Form ref={formRef} onSubmit={handleSubmit}>
            {error && (<Alert>{error}</Alert>)}
            {!guicheSelecionado && !loading && renderSelectGuiche()}
            {guicheSelecionado && !loading && (
              <>
                <div className="row">
                  <div className="col-12 col-sm-6 mx-auto justify-content-center d-flex">
                    <div className="icon-person-queue  rounded-circle">
                      <div style={{ height: '100%' }}  >
                        {
                          guicheSelecionado?.users_supported?.length > 0 &&
                          (
                            <FontAwesomeIcon style={{ height: '100%' }} className="mx-auto  d-block" icon={faUserAlt} />
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mx-auto ">
                  <div className="col-12 justify-content-center d-flex">
                    {
                      guicheSelecionado?.users_supported?.length > 0 &&
                      <>
                        <div>
                          {guicheSelecionado?.users_supported[0]?.name}<br />
                        </div>
                        <div>
                          {
                            guicheSelecionado?.users_supported?.length > 1 &&
                            <div style={{ marginLeft: '5px' }}>
                              {`+`} {guicheSelecionado?.users_supported?.length - 1}
                            </div>
                          }
                        </div>
                      </>
                    }
                  </div>

                  <div className="col-12 justify-content-center d-flex" >
                    <b>{guicheSelecionado?.users_supported && guicheSelecionado?.users_supported[0]?.service}</b>
                  </div>

                  <div className="col-12 justify-content-center d-flex" >

                    <span className="badge badge-secondary mt-2 mb-2">
                      {guicheSelecionado && guicheSelecionado?.name} -
                      {guicheSelecionado && optionsStatus.filter(item => item.value == statusSelecionado)[0].label}
                    </span>


                  </div>
                  <div className="col-12 justify-content-center d-flex">
                    {
                      statusSelecionado === BUSY && <Cronometro date_initial={guicheSelecionado?.date_start_mileseconds} />
                    }
                  </div>
                  <div className="col-12 justify-content-center d-flex">
                    <button
                      className="btn btn-primary mb-2 btn-sm"
                      onClick={handleAvailable}
                      disabled={[AVAILABLE, BUSY].includes(statusSelecionado)}
                    >
                      <FontAwesomeIcon className="" icon={faVideo} />
                      <span className="pl-2">Iniciar Video Atendimento</span>
                    </button>
                  </div>
                  <p className='text-secondary'>
                    Ao clicar no botão acima o status da sala ficará <b>disponível</b> e a qualquer momento um contribuinte poderá entrar na sala para ser atendimento. Desejo um bom atendimento!
                  </p>
                  <div className="col-12 justify-content-center d-flex">
                    <button
                      className="btn btn-success mr-2 mb-2 btn-sm btn-block text-justify"
                      onClick={pushLinkMeet}
                      disabled={![AVAILABLE, BUSY].includes(statusSelecionado)}
                    >
                      <FontAwesomeIcon className="" icon={faPlay} />
                      <span className="pl-2">Entrar na sala</span>
                    </button>
                    <button
                      className="btn btn-danger btn-sm mb-2  btn-block mt-0 text-justify"
                      onClick={handleFinish}
                      disabled={[FINISH, PAUSE, UNAVAILABLE].includes(statusSelecionado)}
                    >
                      <FontAwesomeIcon className="" icon={faStop} />
                      <span className="pl-2"> Finalizar Atendimento</span>
                    </button>
                  </div>

                  <div className="col-12 justify-content-center d-flex">
                    <button
                      className="btn btn-secondary btn-sm mr-2 btn-block text-justify"
                      onClick={handlePause}
                      disabled={[UNAVAILABLE, AVAILABLE, PAUSE, BUSY].includes(statusSelecionado)} >
                      <FontAwesomeIcon className="" icon={faCoffee} />
                      <span className="pl-2">Pausa no Atendimento</span>
                    </button>

                    <button
                      className="btn btn-secondary btn-sm mr-2 mt-0 btn-block text-justify"
                      onClick={handleUnvailable}
                      disabled={[AVAILABLE, UNAVAILABLE, BUSY].includes(statusSelecionado)}>
                      <FontAwesomeIcon className="" icon={faDoorOpen} />
                      <span className="pl-2"> Ficar Indisponível</span>
                    </button>
                  </div>
                </div>
                <div className="row mt-3">
                  {
                    handleShowMeetLink() && (
                      <small>Link da sala atual:
                        <a target='_blank' href={guicheSelecionado?.meet_link}>{guicheSelecionado?.meet_link}</a>
                      </small>
                    )
                  }

                </div>
              </>
            )}
          </Form>
        </div>

        <div className="col-md-6">
          {guicheSelecionado && <HistoricoBalcao statusSelecionado={statusSelecionado} guicheSelecionado={guicheSelecionado} />}
        </div>
      </div>
      <ModalConfirm show={showModalConfirm} onHide={handleHideModalConfirm} logout={logout} />
    </Container>
  );
}
